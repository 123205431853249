import React from "react";
import ReactDOM from "react-dom";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider, useStyletron } from "baseui";
import { Client as Styletron } from "styletron-engine-atomic";

import { Display1, Display3 } from "baseui/typography";

import "./index.css";

const engine = new Styletron();

function Home() {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: theme.sizing.scale1200,
      })}
    >
      <div
        className={css({
          display: "flex",
          width: "80%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          [theme.mediaQuery.large]: {
            width: "960px",
          },
        })}
      >
        <Display3 display={["block", "block", "none"]}>
          No more<br></br>bad meetings.
        </Display3>
        <Display1 display={["none", "none", "block"]}>
          No more<br></br>bad meetings.
        </Display1>
      </div>
    </div>
  );
}

ReactDOM.render(
  <StyletronProvider value={engine}>
    <BaseProvider theme={LightTheme}>
      <Home />
    </BaseProvider>
  </StyletronProvider>,
  document.getElementById("root")
);
